import { FormControl } from "@angular/forms";

export enum CustomFieldEntity {
  Company = 0,
  Client = 1,
  User = 2,
}

export interface CustomFieldSchemaModel {
  customFieldEntity: CustomFieldEntity;
  fieldName?: string;
  defaultFieldValue?: string;
  createdAt: Date;
  updatedAt?: Date;
  tempId?: string; // this is only for the UI
}

export interface InsertCustomFieldSchemaModel {
  customFieldEntity: CustomFieldEntity;
  fieldName: string;
  defaultFieldValue?: string;
}

export interface UpdateCustomFieldSchemaModel {
  customFieldEntity: CustomFieldEntity;
  fieldName: string;
  newFieldName?: string;
  defaultFieldValue?: string;
}

export interface DeleteCustomFieldSchemaModel {
  customFieldEntity: CustomFieldEntity;
  fieldName: string;
}

export class CustomFieldSchemaForm {
  oldFieldName: FormControl<string> | undefined;
  fieldName: FormControl<string>;
  defaultValue: FormControl<string | null>;
  associatedEntity: FormControl<CustomFieldEntity | null>;
}

export interface CustomFieldSchemaFormValue {
  oldFieldName: string | undefined;
  fieldName: string;
  defaultValue: string;
  associatedEntity: CustomFieldEntity;
}

export interface ValidationProblemDetails {
  type?: string,
  title?: string,
  status?: number,
  detail?: string,
  instance?: string,
  errors?: {[key:string]: string},
}

export interface CustomFieldEntryModel {
  entityId: string;
  fieldName?: string;
  fieldValue?: string;
  createdAt: Date;
  updatedAt?: Date;
  isDefaultValue: boolean;
}

export interface CustomFieldEntryForm {
  [key: string]: FormControl<string | undefined>;
}

export interface UpsertCustomFieldEntryModel {
  customFieldEntity: CustomFieldEntity;
  entityId: string;
  fieldName: string;
  fieldValue: string;
}

export interface DeleteCustomFieldEntryModel {
  customFieldEntity: CustomFieldEntity;
  entityId: string;
  fieldName: string;
}

export interface CustomFieldByName {
  customFieldEntity: CustomFieldEntity;
  fieldName: string;
  fieldValue: string;
}

export interface AddedField {
  entryToAdd: UpsertCustomFieldEntryModel;
  originalEntry: CustomFieldEntryModel;
}

export interface UpdatedField {
  entryToUpdate: UpsertCustomFieldEntryModel;
  originalEntry: CustomFieldEntryModel;
}

export interface DeletedField {
  entryToDelete: DeleteCustomFieldEntryModel;
  originalEntry: CustomFieldEntryModel;
  defaultFieldValue: string;
}

export interface FieldChanges {
  addedFields: AddedField[];
  updatedFields: UpdatedField[];
  deletedFields: DeletedField[];
}